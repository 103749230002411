import * as m from 'mithril';
import stream from 'mithril/stream';

import {Component,Vnode} from 'Components/Component';
import classnames from 'classnames';

import Layout from 'Modules/Layout/FullWidth';


import LoadingButton from 'Components/Button/LoadingButton';
import TextField from 'Components/TextField/TextField';

import ChoiceGroup from 'Components/ChoiceGroup/ChoiceGroup';

import AuthenticationService from 'Services/Authentication';

import GlobalDialog from 'Utilities/GlobalDialog';

import {endpoint} from 'config';
import './emailRegister.scss';

import l from 'lang';

import {SEX, STUDY_PROGRAMS,STUDENT_LEVEL,STUDIEN_LOCATION} from 'Services/Constants';
import Datepicker from 'Components/Datepicker/Datepicker';
import Button from 'Components/Button/Button';
import Checkbox from 'Components/Checkbox/Checkbox';

interface Attr{
    return? : string;
}

interface State{
    lrzId : prop<string>;
    firstname : prop<string>;
    lastname : prop<string>;
    studentId : prop<number>;
    sex :  prop<{key:SEX,value:string}>;
    nationality : prop<string>;
    birthday : prop<string>;
    phoneNumber :  prop<number>;
    studiengang: prop<{key:STUDY_PROGRAMS,value:string}>;
    studienlevel : prop<{key:STUDENT_LEVEL,value:string}>;
    studienlocation : prop<{key : STUDIEN_LOCATION, value: string}>;
    studiensemester : prop<number>;
    specialNeeds : prop<boolean>;
    state : "login" | "sentmail" | "createuser" | "closed";
    isValid : boolean;

}

class EmailLogin extends Component<State,Attr>{


    static oninit(this: State, vnode : Vnode<State,Attr>){

        this.isValid = false;

        if(AuthenticationService.hasToken()){
            if (vnode.attrs.return) {
                m.route.set(vnode.attrs.return)
            } else {
                m.route.set("/welcome");
            }
        }


        this.lrzId = stream("");
        this.firstname = stream("");
        this.lastname = stream("");
        this.studentId = stream("");
        
        this.nationality = stream("");
        this.birthday = stream("");
        this.phoneNumber = stream("");
       

        this.studiengang  = stream({key:-1});
        this.studienlevel = stream({key:-1});
        this.studienlocation = stream({key : -1});
        this.studiensemester = stream("");
        this.specialNeeds = stream(false);

        this.state = "login";

        m.request({
            method : "GET",
            url : endpoint + "/appstate/open"
        }).then(data => {
            if (data.state == "closed") {
                this.state = "closed";
            }
        });
        

        this.sex =  stream({key:-1});

        var ends_with =  function(value,search) {
                return value.substring(value.length - search.length, value.length) === search;
        }

        this.lrzId.isInvalid = (value : string) => {
            if (!value.match(/^[a-z]{2}[0-9]{2}[a-z]{3}$/)) return l("register.form.error.tum_id");
            return false;
        }

        this.studienlocation.isInvalid = this.studiengang.isInvalid =  this.sex.isInvalid = (value : { key : any, value : string}) => {
                if(!value.key || value.key == -1) return l('form.error.select_option')
                return false;
        }

         this.studienlevel.isInvalid = (value : { key : STUDENT_LEVEL, value : string}) => {
            if(!value.key || value.key as any == -1) return l('form.error.select_option')
            return false;
         }

        this.studentId.isInvalid = (value : string) => {
            if(!value.startsWith("0") || value.length != 8) return l("register.form.error.student_id");
            if(parseInt(value) != value as any) return l("form.error.only_number");
            return false;
        }

        this.studiensemester.isInvalid = (value: number ) => {
            if ( value <= 0 || value > 100 ) return  l("register.form.error.valid_semester");
            return false;
        }

       
        this.nationality.isInvalid = this.firstname.isInvalid = this.lastname.isInvalid = (value : string) => {
            if(value.length == 0) return l("form.error.value")
            return false;
        }


        this.birthday.isInvalid = (value : string) => {
                let date;
                if(value.length == 0 || !value.match(/^\d{4}-\d{2}-\d{2}$/)) return "Please enter a value in the format YYYY-MM-DD";
                try{
                    date = new Date(value);
                } catch(e){
                    return l("form.error.date")
                }
                return false;
        }



        this.phoneNumber.isInvalid = (value : string) => {
            if(parseInt(value) != value as any) return l("form.error.only_number");
            if(!value.startsWith("00")) return l("register.form.error.phone_number");
            return false;
        }



        let invalids =  [
            this.lrzId,
            this.firstname,
            this.lastname,
            this.studentId,
            this.nationality,
            this.birthday,
            this.phoneNumber,
            this.studiengang,
            this.studienlevel,
            this.studiensemester,
            this.studienlocation,
            this.sex
        ]


        stream.combine(() => {
            let tmp_valid = true;
            for (var key in invalids) {
                if (invalids.hasOwnProperty(key) && typeof invalids[key] === "function" && (invalids[key] as any).isInvalid) {
                    if((invalids[key] as any).isInvalid((invalids[key] as any)() as prop<any>) !== false){
                        tmp_valid = false;
                        break;
                    }
                }
            }
            this.isValid = tmp_valid;
        }, invalids);


    }


    static __login(this : State, vnode : Vnode<State,Attr>){
            return m.request({
                method : "GET",
                url : endpoint + "/emailusers/" + this.lrzId()
            }).then(data => {
                    return m.request({
                        method : "PUT",
                        url : endpoint + "/emailusers/" + this.lrzId()
                    }).then(data => {
                        this.state = "sentmail";
                    });
            }).catch((e : { code : number, message : string, errors : string[]}) => {
                if(e.code == 404){
                    this.state = "createuser";
                } else  if(e.code == 429){
                    // ratelimit
                    GlobalDialog.error(l("register.form.error.message.title"),
                            m("",[
                            m("p",l("register.form.error.message.ratelimit")),
                            m("ul", e.errors ? e.errors.map(e => m("li",e)) : "")
                            ])
                    , "Ok");

                } else  if(e.code == 422){
                    // hibernate validation error
                    GlobalDialog.error(l("register.form.error.message.title"),
                            m("",[
                            m("p",l("register.form.error.message")),
                            m("ul", e.errors.map(e => m("li",e)))
                            ])
                    , "Ok");
                } else {
                    GlobalDialog.error("Error","We could not log you in. Generic " + e.code + " Error with following message : " + JSON.stringify(e.message), "Ok");
                }
        });
    }

    static __createUser(this : State, vnode : Vnode<State,Attr>){
 
        var user = {
            studentId : this.studentId(),
            firstname : this.firstname(),
            lastname : this.lastname(),
            sex : this.sex(),
            nationality : this.nationality(),
            birthday : this.birthday(),
            lrzId : this.lrzId(),
            phoneNumber : this.phoneNumber(),
            studiengang : this.studiengang(),
            studienlevel : this.studienlevel(),
            studiensemester : this.studiensemester(),
            studienlocation : this.studienlocation(),
            specialNeeds : this.specialNeeds()
        }

        return m.request({
            method  : "POST",
            url : endpoint + "/emailusers",
            data : user
        }).then( () => this.state = "sentmail")
        .catch((e : { code : number, message : string, errors : string[]}) => {
                if(e.code == 422) {
                    // hibernate validation error
                    GlobalDialog.error(l("register.form.error.message.title"),
                            m("",[
                            m("p",l("register.form.error.create_message")),
                            m("ul", e.errors.map(e => m("li",e)))
                            
                            ])
                    , "Ok");
                 } else  if(e.code == 429){
                    // ratelimit
                    GlobalDialog.error(l("register.form.error.message.title"),
                            m("",[
                                m("p",l("register.form.error.message.ratelimit")),
                            m("ul", e.errors ? e.errors.map(e => m("li",e)) : "")
                            ])
                    , "Ok");


                } else {
                    GlobalDialog.error("Error","We could not create your user account. Generic " + e.code + " Error with following message : " + JSON.stringify(e.message), "Ok");
                }
        });
       
    }

    static view(this: State, vnode : Vnode<State,Attr>){
        return <Layout title={l("login.title")} subTitle={l("login.subtitle")}>
            { this.state == "login" ? <div>
                <p>{l("login.entry_text")}</p>
                    <form className="EmailRegister__form" onsubmit={(e) => e.preventDefault()}>
              
                        <TextField label={l("global.tumid.label")} bind={this.lrzId} description={l("login.form.tum_id.description")} />
                        <LoadingButton action={EmailLogin.__login.bind(this,vnode)} primary  disabled={(this.lrzId.isInvalid && !!this.lrzId.isInvalid(this.lrzId()))}> { l("login.form.button")} </LoadingButton>
                    </form>
                    <p>{l("login.outro_text")}</p>
                    </div>
                : "" 
            }

        { this.state == "closed" ?
                <div className="EmailRegister__emailSent">
                        <h3>The application period has not started <span onclick={() => this.state = "login"}> yet.</span> </h3>
                        Please consult the website of the International Office for more information.
                </div> : ""
            } 


            { this.state == "sentmail" ?
                <div className="EmailRegister__emailSent">
                    <h3>{l("login.sentmail.title")}</h3>
                    <p>{l("login.sentmail.text")}</p>
                    <p>{l("login.sentmail.outro")}</p>
                </div> : ""
            } 

            { this.state == "createuser" ?
                <form className="EmailRegister__form" onsubmit={(e) => e.preventDefault()}>
                    <p>{l("register.form.intro")}</p>
                    <ul className="EmailRegister__form_list">
                        <li>
                            <TextField label={l("global.studentid.label")}  bind={this.studentId} />
                        </li>
                        <li>
                            <TextField isDisabled label={l("global.tumid.label")} bind={this.lrzId} />
                        </li>
                        <li>
                            <TextField label={l("global.firstname.label")} bind={this.firstname} />
                        </li>
                        <li>
                            <TextField label={l("global.lastname.label")} bind={this.lastname} />
                        </li>
                        <li>
                             <ChoiceGroup isHorizonal bind={this.sex} items={[
                                 { key: "MALE", value : l("global.sex.male.label") }, 
                                 {key : "FEMALE", value : l("global.sex.female.label")},
                                 {key : "OTHER", value : l("global.sex.other.label")}
                                 ]  as {key : SEX, value : string}[]} label={l("global.sex.label")} />
                        </li>
                        <li>
                            <TextField label={l("global.nationality.label")} bind={this.nationality} />
                        </li>
                        <li>
                            <Datepicker label={l("global.birthday.label")} value={this.birthday} type="text"  description={l("global.birthday.description")} />
                        </li>
                        <li>
                            <TextField label={l("global.phonenumber.label")} bind={this.phoneNumber} type="text" description={l("global.phonenumer.description")} />
                        </li>
                        <li>


                            <ChoiceGroup isHorizonal bind={this.studiengang} items={[
                                { key: "BMT", value : "BMT (B.Sc. TUM BWL)" },
                                { key: "BSMT", value : "BSMT" },
                                {key : "MMT", value : "MMT"},
                                {key : "MIM", value : "MiM"},
                                {key : "MCS", value : "MCS"},
                                {key : "MSMT", value : "MSMT"},
                                {key : "BMDS", value : "BMDS"},
                                {key : "MMDT", value : "MMDT"},
                                {key : "FIM", value : "FIM"}
                                ]  as {key : STUDY_PROGRAMS, value : string}[]} label="Current Study Program" description="Current Study Program at TUM" />
                        </li>

                        <li>
                            <ChoiceGroup isHorizonal bind={this.studienlocation} items={[
                                { key: "MUENCHEN", value : "München" },
                                {key : "STRAUBING", value : "Straubing"},
                                {key : "HEILBRONN", value : "Heilbronn"}]  as {key : STUDIEN_LOCATION, value : string}[]} label="Study Location" description="Please select your study location" />
                        </li>

                        <li>
                            <ChoiceGroup description="Current Study Level at TUM" isHorizonal bind={this.studienlevel} items={[
                                { key: "BACHELOR", value : "Bachelor" },
                                {key : "MASTER", value : "Master"}
                                ]  as {key : STUDENT_LEVEL, value : string}[]} label="Current Study Level" />
                        </li>

                        <li>
                            <TextField label="Current Semester" bind={this.studiensemester} type="text" description="Current subject related semester (Fachsemester) at TUM" />
                        </li>
                        <li>
                            <p>Do you have any disability that requires special support?</p>
                            <Checkbox label="Yes, I have special needs" bind={this.specialNeeds} />
                        </li>
                        <li>
                           {
                               l("register.form.legal").split("\n").map(line => <p>{line}</p>)
                           }
                            <p>I have read and accept the <a href="https://www.mgt.tum.de/privacy-policy/" target="_blank" rel="noopener"> privacy policy</a> of the TUM School of Management.</p>
                        </li>
                        <li style="margin-top:3em;">

                            <LoadingButton action={EmailLogin.__createUser.bind(this,vnode)} disabled={!this.isValid} primary type="submit">Accept Terms &amp; Create Account</LoadingButton>
                            {!this.isValid ? <p className="ms-TextField-errorMessage ms-u-slideDownIn20">Please fix all errors</p> : "" }
                        </li>
                    </ul>

                </form>
            
            : ""}
        </Layout>
    }
}

export default (EmailLogin);
