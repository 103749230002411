
export interface HTMLBaseAttributes{
    href? : string;
    defaultChecked? : any;
    defaultValue? : any;
    accept? : any;
    acceptCharset? : any;
    accessKey? : any;
    action? : any;
    allowFullScreen? : any;
    allowTransparency? : any;
    alt? : any;
    async? : any;
    autoComplete? : any;
    autoFocus? : any;
    autoPlay? : any;
    capture? : any;
    cellPadding? : any;
    cellSpacing? : any;
    charSet? : any;
    challenge? : any;
    checked? : any;
    children? : any;
    classID? : any;
    className? : any;
    cols? : any;
    colSpan? : any;
    content? : any;
    contentEditable? : any;
    contextMenu? : any;
    controls? : any;
    coords? : any;
    crossOrigin? : any;
    data? : any;
    dateTime? : any;
    default? : any;
    defer? : any;
    dir? : any;
    download? : any;
    draggable? : any;
    encType? : any;
    form? : any;
    formAction? : any;
    formEncType? : any;
    formMethod? : any;
    formNoValidate? : any;
    formTarget? : any;
    frameBorder? : any;
    headers? : any;
    height? : any;
    hidden? : any;
    high? : any;
    hrefLang? : any;
    htmlFor? : any;
    httpEquiv? : any;
    icon? : any;
    id? : any;
    inputMode? : any;
    integrity? : any;
    is? : any;
    keyParams? : any;
    keyType? : any;
    kind? : any;
    label? : any;
    lang? : any;
    list? : any;
    loop? : any;
    low? : any;
    manifest? : any;
    marginHeight? : any;
    marginWidth? : any;
    max? : any;
    maxLength? : any;
    media? : any;
    mediaGroup? : any;
    method? : any;
    min? : any;
    minLength? : any;
    multiple? : boolean;
    muted? : any;
    name? : any;
    noValidate? : any;
    open? : any;
    optimum? : any;
    pattern? : any;
    placeholder? : any;
    poster? : any;
    preload? : any;
    radioGroup? : any;
    readOnly? : any;
    rel? : any;
    required? : any;
    role? : any;
    rows? : any;
    rowSpan? : any;
    sandbox? : any;
    scope? : any;
    scoped? : any;
    scrolling? : any;
    seamless? : any;
    selected? : any;
    shape? : any;
    size? : any;
    sizes? : any;
    span? : any;
    spellCheck? : any;
    src? : any;
    srcDoc? : any;
    srcLang? : any;
    srcSet? : any;
    start? : any;
    step? : number;
    style? : any;
    summary? : any;
    tabIndex? : any;
    title? : any;
    type? : any;
    useMap? : any;
    value? : any | number;
    width? : any;
    wmode? : any;
    wrap? : any;
}

export interface HTMLMithrilAttributes{
    key? : string | number;
    oncreate? : (vnode : Vnode<any,any>) => void; 
    onbeforeremove? : (vnode : Vnode<any,any>) => void; 
}

export interface HTMLBaseEvents{
    oncopy? : (e : Event) => void;
    oncut? : (e : Event) => void;
    onpaste? : (e : Event) => void;
    oncompositionend? : (e : Event) => void;
    oncompositionstart? : (e : Event) => void;
    oncompositionupdate? : (e : Event) => void;
    onfocus? : (e : Event) => void;
    onfocuscapture? : (e : Event) => void;
    onblur? : (e : Event) => void;
    onblurcapture? : (e : Event) => void;
    onchange? : (e : Event) => void;
    oninput? : (e : Event) => void;
    onsubmit? : (e : Event) => void;
    onload? : (e : Event) => void;
    onerror? : (e : Event) => void;
    onkeydown? : (e : KeyboardEvent) => void;
    onkeydowncapture? : (e : KeyboardEvent) => void;
    onkeypress? : (e : KeyboardEvent) => void;
    onkeyup? : (e : KeyboardEvent) => void;
    onabort? : (e : Event) => void;
    oncanplay? : (e : Event) => void;
    oncanplaythrough? : (e : Event) => void;
    ondurationchange? : (e : Event) => void;
    onemptied? : (e : Event) => void;
    onencrypted? : (e : Event) => void;
    onended? : (e : Event) => void;
    onloadeddata? : (e : Event) => void;
    onloadedmetadata? : (e : Event) => void;
    onloadstart? : (e : Event) => void;
    onpause? : (e : Event) => void;
    onplay? : (e : Event) => void;
    onplaying? : (e : Event) => void;
    onprogress? : (e : Event) => void;
    onratechange? : (e : Event) => void;
    onseeked? : (e : Event) => void;
    onseeking? : (e : Event) => void;
    onstalled? : (e : Event) => void;
    onsuspend? : (e : Event) => void;
    ontimeupdate? : (e : Event) => void;
    onvolumechange? : (e : Event) => void;
    onwaiting? : (e : Event) => void;
    onclick? : (e : Event) => void;
    onclickcapture? : (e : Event) => void;
    oncontextmenu? : (e : Event) => void;
    ondoubleclick? : (e : Event) => void;
    ondrag? : (e : Event) => void;
    ondragend? : (e : Event) => void;
    ondragenter? : (e : Event) => void;
    ondragexit? : (e : Event) => void;
    ondragleave? : (e : Event) => void;
    ondragover? : (e : Event) => void;
    ondragstart? : (e : Event) => void;
    ondrop? : (e : Event) => void;
    onmousedown? : (e : Event) => void;
    onmousedowncapture? : (e : Event) => void;
    onmouseenter? : (e : Event) => void;
    onmouseleave? : (e : Event) => void;
    onmousemove? : (e : Event) => void;
    onmouseout? : (e : Event) => void;
    onmouseover? : (e : Event) => void;
    onmouseup? : (e : Event) => void;
    onmouseupcapture? : (e : Event) => void;
    onselect? : (e : Event) => void;
    ontouchcancel? : (e : PointerEvent) => void;
    ontouchend? : (e : PointerEvent) => void;
    ontouchmove? : (e : PointerEvent) => void;
    ontouchstart? : (e : PointerEvent) => void;
    onscroll? : (e : Event) => void;
    onwheel? : (e : Event) => void;
}

export interface HTMLLinkAttributes{
    href? : string;
    target? : string;
}

export interface HTMLFormAttributes{
    disabled? : boolean;
}

export interface HTMLLabelAttributes{
    for? : string;
}

export interface SVGBase{
    viewBox? : any;
    preserveAspectRatio? : any;
    attributeName? : any;
    x? : any;
    y? : any;
    fill?: any;
    d? : any;
    stroke? : any;
    from? : any;
    to? : any;
    dur? : any;
    type? : any;
    repeatCount? : any;

}


export interface Vnode<CState,CAttr>{
    state: CState;
    attrs: CAttr;
    children : any[];
    dom: HTMLElement;
}

export class Component<CState,CAttr> {
    props : CAttr & HTMLBaseAttributes & HTMLMithrilAttributes;
}



/* tslint:disable */
// require all type data here

type HTMLBase = HTMLBaseAttributes & HTMLBaseEvents & HTMLMithrilAttributes;

declare global{
 namespace JSX {
    interface ElementAttributesProperty {
        props; // specify the property name to use
    }
    interface IntrinsicElements{
        label : HTMLLabelAttributes & HTMLBase;
        div : HTMLBase;
        p : HTMLBase;
        span : HTMLBase;
        details : HTMLBase;
        summary : HTMLBase;
        ul : HTMLBase;
        ol : HTMLBase;
        li : HTMLBase;
        tr : HTMLBase;
        table : HTMLBase;
        tbody : HTMLBase;
        thead : HTMLBase;
        td : HTMLBase;
        h1 : HTMLBase;
        h2 : HTMLBase;
        h3 : HTMLBase;
        h4 : HTMLBase;
        h5 : HTMLBase;
        th : HTMLBase;
        strong : HTMLBase;
        i : HTMLBase;
        em : HTMLBase;
        form : HTMLFormAttributes & HTMLBase;
        a : HTMLLinkAttributes & HTMLBase;
        button : HTMLFormAttributes & HTMLBase;
        input : HTMLFormAttributes & HTMLBase;
        textarea : HTMLFormAttributes & HTMLBase;
        svg : HTMLBase & SVGBase;
        path : HTMLBase & SVGBase;
        g : HTMLBase & SVGBase;
        rect : HTMLBase & SVGBase;
        animateTransform : SVGBase;
        time : HTMLBase & { datetime : String};

        nav : HTMLBase;
        article : HTMLBase;
        header : HTMLBase;
        br : HTMLBase;
        pre : HTMLBase;
        small : HTMLBase;
        iframe : HTMLBase
        embed : HTMLBase

    }
}
}



export default Component;
