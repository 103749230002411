import * as m from 'mithril';

var routes = {};

export function startRoute(dom: HTMLElement, defaultUrl: string, routeObject: any) {
    routes = routeObject;
    m.route(dom, defaultUrl, routeObject);
}

export  function Link (vnode) {
    m.route.link(vnode);

    // preload on mouse over
    vnode.dom.onmouseenter = () =>  {
        if(vnode.state.__router_preload_last && (Date.now() - vnode.state.__router_preload_last < 10000) ){
            // cancel if last invocation was to recent
            return;
        }
        vnode.state.__router_preload_last = Date.now();
        findRoutePreloader(routes, vnode.attrs.href, "mouseover");
    }

    // also preload when just on page after some time
    // attach always, but on invocation we cancel preloads with any keys
    // (see below in findRoutePreloader)
    window.setTimeout(() => {
        if(vnode.state.__router_preload_last && (Date.now() - vnode.state.__router_preload_last < 10000) ){
            // cancel if last invocation was to recent
            return;
        }
        vnode.state.__router_preload_last = Date.now();
        findRoutePreloader(routes, vnode.attrs.href, "time");
    }, 1000 + (2000 * Math.random()))

}

var triggerPreload = function(obj,routeParams, path){
    if(obj.preload){
        obj.preload(routeParams,path);
    } else if (obj.onmatch) {
        // is hidden behind promise

        obj.onmatch().then( pobj => {
            if (pobj.preload) {
                pobj.preload(routeParams,path);   
            }
        })


    }
}

var findRoutePreloader = function (routeMap, path, invocation : "mouseover" | "time"): void {
    
    var routeParams = {};
    for (var route in routeMap) {
        if (routeMap.hasOwnProperty(route)) {
            if (route === path) {
                triggerPreload(routeMap[route],routeParams, path);
                return;
            }
            var matcher = new RegExp("^" + route.replace(/:[^\/]+?\.{3}/g, "(.*?)").replace(/:[^\/]+/g, "([^\\/]+)") + "\/?$");
            if (matcher.test(path)) {

                path.replace(matcher, function () {
                    var keys = route.match(/:[^\/]+/g) || [];

                    if (keys.length > 0  && invocation == "time") {
                        // abort preloading if we have keys in the url and had invocation after time
                        // this should prevent loading a whole page of links after time
                        return;
                    }

                    var values = [].slice.call(arguments, 1, -2);
                    for (var i = 0, len = keys.length; i < len; i++) {
                        routeParams[keys[i].replace(/:|\./g, "")] = decodeURIComponent(values[i]);
                    }
                });

                 triggerPreload(routeMap[route],routeParams, path);

                 // has found preload -> exit
                 return;

            }
        }
    }

};


export default Link;
